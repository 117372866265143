@import url(https://fonts.googleapis.com/css2?family=Antonio:wght@100;300;400;500;700&display=swap);
html, body {
  margin: 0;
  padding: 0;

  font-family: "Gotham";
}
@font-face {
  font-family: 'DIN';
  font-weight: normal;
  src: local('DINNextCYR-Regular'),
    url('https://uploads.aaaai.hlxcongress.digital/assets/Fonts/1bf8d1be-e5d2-490f-927d-8bf67961b426.woff')
      format('woff'),
    url('https://uploads.aaaai.hlxcongress.digital/assets/Fonts/1bdb82ac-585e-4a3a-9491-f3bc0dd0fe77.woff2')
      format('woff2');
  font-display: block;
}

@font-face {
  font-family: 'DIN';
  font-weight: bold;
  src: local('DINNextCYR-Bold'),
    url('https://uploads.aaaai.hlxcongress.digital/assets/Fonts/3bfb2f5f-be2a-4142-ba19-3c38f4963f92.woff')
      format('woff'),
    url('https://uploads.aaaai.hlxcongress.digital/assets/Fonts/5b3dcf7c-d381-4d6b-8ec5-eed99ead0484.woff2')
      format('woff2');
  font-display: block;
}

@font-face {
  font-family: 'Gotham';
  font-weight: normal;
  src: local('Gotham-Medium'),
    url('https://uploads.type2congress.com/Type2virtualbooth_ESPD21_2d210590-f485-49b8-a776-88b4c4af94c6/assets/fonts/Gotham-Medium.woff')
      format('woff'),
    url('https://uploads.type2congress.com/Type2virtualbooth_ESPD21_2d210590-f485-49b8-a776-88b4c4af94c6/assets/fonts/Gotham-Medium.woff2')
      format('woff2');
  font-display: block;
}

@font-face {
  font-family: 'Gotham';
  font-weight: bold;
  src: local('Gotham-Bold'),
    url('https://uploads.type2congress.com/Type2virtualbooth_ESPD21_2d210590-f485-49b8-a776-88b4c4af94c6/assets/fonts/Gotham-Bold.woff')
      format('woff'),
    url('https://uploads.type2congress.com/Type2virtualbooth_ESPD21_2d210590-f485-49b8-a776-88b4c4af94c6/assets/fonts/Gotham-Bold.woff2')
      format('woff2');
  font-display: block;
}

body {
  font-family: 'Gotham', sans-serif, Arial;
  margin: 0;
  background: #f4f4f4;
  overflow: auto;
  height: 100vh;
}

